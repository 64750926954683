import React from 'react'
import Container from 'react-bootstrap/Container'
import { Col, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'

import { useNavigate } from 'react-router-dom' // Import useNavigate

function SubNav() {
  const navigate = useNavigate() // Create the navigate function
  return (
    <Container className="mt-3">
      <Row>
        <Col>
          <Button
            id="primary"
            style={{
              background: '#6A99D0',
              width: '100%',
              height: '3.5rem',
              color: 'white',
            }}
            variant="light"
            onClick={() => navigate(`/ClassPlan/${'primary'}`)}
          >
            國小
          </Button>
        </Col>
        <Col>
          <Button
            id="middle"
            style={{
              background: '#EF88AE',
              width: '100%',
              height: '3.5rem',
              color: 'white',
            }}
            variant="light"
            onClick={() => navigate(`/ClassPlan/${'middle'}`)}
          >
            國中
          </Button>
        </Col>
        <Col>
          <Button
            id="high"
            style={{
              background: '#F1CB20',
              width: '100%',
              height: '3.5rem',
              color: 'white',
            }}
            variant="light"
            onClick={() => navigate(`/ClassPlan/${'high'}`)}
          >
            高中
          </Button>
        </Col>
        <Col>
          <Button
            id="university"
            style={{
              background: '#9FCE63',
              width: '100%',
              height: '3.5rem',
              color: 'white',
            }}
            variant="light"
            onClick={() => navigate(`/ClassPlan/${'university'}`)}
          >
            大學
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default SubNav
