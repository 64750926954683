import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const photos = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11",
"12","13","14","15","16","17","18",];

export default function App() {
  return (
    <>
      <Swiper style={{borderRadius:'1rem'}}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        
        {photos.map ((photo,i)=>
            <SwiperSlide>
                <img src={`/images/swiper/${photo.toString()}.jpg`} alt="" />
            </SwiperSlide>
        )}

      </Swiper>
    </>
  );
}