import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

export const NavButton = styled.section`
  @media (max-width: 990px) {
    margin-top: 1rem;
  }
`

const navLinks = [
  { to: '/ClassPlan/primary', label: '課程教案' },
  { to: '/LessonPlan', label: '課程計畫' },
  { to: '/UserGuidance', label: '使用指南' },
  { to: '/References', label: '參考資料' },
  { to: '/QA', label: 'Ｑ＆Ａ' },
  { to: '/TeamIntroduction', label: '團隊介紹' },
]

function Views() {
  const url =
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTF6y7LXX4Rg7NlP-PaFn2j7WR1cQEhd1O2FvV-x0xGvLxJWovW8LwO3NCd03mATIF4uVtxAY56YUUk/pub?gid=479023385&single=true&output=tsv'

  // 取得資料
  const [dataTSV, setDataTSV] = useState('')
  try {
    fetch(url)
      // 轉換成字串
      .then((response) => response.text())
      .then((data) => setDataTSV(data))
  } catch (error) {
    console.error('Error: ', error)
  }

  // 把字串中的換行符號換成定位鍵
  const dataOneline = dataTSV.replaceAll('\r\n', '\t')

  // 利用定位鍵把字串分割成陣列
  const dataArray = dataOneline.split('\t')

  const viewsCount = dataArray[dataArray.length - 2]

  return (
    <div
      style={{
        background: 'black',
        padding: '0.5rem 1.3rem 0.5rem 1.3rem',
        textAlign: 'center',
        maxWidth: '15rem',
        borderRadius: '5rem',
      }}
    >
      <p className="mb-0 text-light">
        統計瀏覽人數：
        <span>{viewsCount}</span>
      </p>
    </div>
  )
}

function NavBar() {
  return (
    <>
      <Navbar
        collapseOnSelect
        style={{
          backgroundColor: 'white',
          top: '0',
          left: '0',
          right: '0',
          position: 'fixed',
          zIndex: '1000',
        }}
        className="py-3"
        expand="xl"
      >
        <Container className="px-5 ">
          <NavLink to="/Home" className="mx-2 text-dark text-decoration-none">
            <img
              src="./images/banner.png"
              style={{ height: '3.5rem' }}
              alt=""
            />
          </NavLink>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end flex-grow-1 pe-3 align-items-center">
              {navLinks.map(({ to, label }) => (
                <NavButton key={to}>
                  <Nav.Link
                    as={NavLink}
                    eventKey="0"
                    to={to}
                    className="nav-link Styles mx-2 text-dark text-decoration-none"
                  >
                    {label}
                  </Nav.Link>
                </NavButton>
              ))}

              <Views />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default NavBar
