import React from "react";

import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function UserGuidance() {
  return (
    <>
      <Container style={{ marginTop: "6rem" }}>
        <Row className=" justify-content-center">
          <Col xs={10} md={6} className="p-2">
            <div style={{ height: "18rem" }} className="p-5">
              <div
                className="mx-2 text-dark text-decoration-none d-flex align-items-center"
                style={{ height: "100%" }}
              >
                <div>
                  <h4>我們有一套專門幫助學生規劃的資源！</h4>
                  <h6>多元的教材與專業的師資</h6>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={10} md={6} className="p-2">
            <div
              style={{
                height: "18rem",
                backgroundImage: `url(/images/swiper/5.jpg)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              className="p-3 shadow rounded-4"
            ></div>
          </Col>
        </Row>
      </Container>

      <Container style={{ marginBottom: "10rem" }} className="mt-5">
        <Row>
          <Col>
            <h4 className="mb-5 text-center">你的角色是？</h4>
          </Col>
        </Row>
        <Row className="mb-5 justify-content-center">
          <Col xs={10} md={4}>
            <Link to="*" className="mx-2 text-dark text-decoration-none">
              <div className="text-center">
                <img
                  style={{ width: "30%" }}
                  src="/images/cartor/使用指南＿學生版.png"
                  alt=""
                />
                <h6 className="text-center mt-3">學生</h6>
              </div>
            </Link>
          </Col>
          <Col xs={10} md={4}>
            <Link to="*" className="mx-2 text-dark text-decoration-none">
              <div className="text-center">
                <img
                  style={{ width: "30%" }}
                  src="/images/cartor/使用指南＿教師版.png"
                  alt=""
                />
                <h6 className="text-center mt-3">教師</h6>
              </div>
            </Link>
          </Col>
          <Col xs={10} md={4}>
            <Link to="*" className="mx-2 text-dark text-decoration-none">
              <div className="text-center">
                <img
                  style={{ width: "30%" }}
                  src="/images/cartor/使用指南＿家長版.png"
                  alt=""
                />
                <h6 className="text-center mt-3">家長</h6>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UserGuidance;
