import React, { useState } from "react";
import Card from "react-bootstrap/Card";

// function NewsDefault() {
// 	return (
// 		<Card className="bg-dark text-white p-4" key={0}>
// 			<Card.Title>數位教育分心網站上線啦</Card.Title>
// 			<Card.Text>學習者進行網路資訊搜尋與學習時，容易在學習任務中產生多工的現象(Wu, 2020)。部分學習者還可能因社群媒體的干擾而導致注意力分散，中斷了正在進行中的學習任務，上述情形即為「數位分心」(Digital Distraction)(Wu, 2015, 2017)。</Card.Text>
// 			<Card.Text>Last updated 3 mins ago</Card.Text>
// 			<Card.Text>國立陽明交通大學教育研究所</Card.Text>
// 		</Card>
// 	);
// }

function GetData() {
	const url = "https://docs.google.com/spreadsheets/d/e/2PACX-1vTF6y7LXX4Rg7NlP-PaFn2j7WR1cQEhd1O2FvV-x0xGvLxJWovW8LwO3NCd03mATIF4uVtxAY56YUUk/pub?gid=0&single=true&output=tsv";

	// 取得資料
	const [dataTSV, setDataTSV] = useState('');
	try {
		fetch(url)
			// 轉換成字串
			.then(response => response.text())
			.then((data) => setDataTSV(data));
	} catch (error) {
		console.error('Error: ', error);
	}

	// 字串去除換行符號
	const dataOneline = dataTSV.replaceAll('\r\n', '\t');
	
	// 利用定位鍵把字串分割成陣列
	const dataArray = dataOneline.split('\t');
	
	// 陣列轉換成存放物件的陣列
	let dataObject = [];
	for (let i = 0; i < dataArray.length; i+=4) {
		dataObject.push({
			title: dataArray[i],
			content: dataArray[i + 1],
			author: dataArray[i + 2],
			date: dataArray[i + 3]
		});
	}

	return dataObject;
}

export default function News() {
	const data = GetData();

	// 用資料產生卡片
	let news: React.JSX.Element[] = [];
	for (let i = (data.length - 1); i > 0; i--) {
		news.push(
			<Card className="bg-dark text-white mb-3 p-4" key={i}>
				<Card.Title>{ data[i].title }</Card.Title>
				<Card.Text>{ data[i].content }</Card.Text>
				<Card.Text>{ data[i].author }</Card.Text>
				<Card.Text>{ data[i].date }</Card.Text>
			</Card>
		);
	}

	// 回傳卡片
	// return ( <NewsDefault /> );
	return ( <>{ news }</> );
}