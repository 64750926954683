import React from 'react';
// import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function JuyueCards() {
  return (
    <Container className='' style={{ textAlign: 'center', marginTop: '6rem' }}>
      <img
        src={"/images/juyue.svg"}
        className="App-logo animate__animated  animate__bounce"
        alt="logo"
      />
      <Row className="justify-content-md-center">
        <Col xs lg="10">
          <h2 className="mt-5">"The Future ML2LAB is Here"</h2>
          <h6 className="mt-5">
            We strive to stay ahead of the curve and bring innovative solutions
            to the table. From cutting-edge technology to revolutionary ideas,
            our mission is to shape the future of website and make our world a
            better place. Our team of experts is passionate about pushing the
            boundaries of what is possible and providing services that make a
            real difference. Visit us today to see how we can help you make the
            future happen.
          </h6>
          <Button
            className="my-5"
            variant="outline-dark"
            href="https://Juyue.tw"
            target="_blank"
            rel="noopener noreferrer"
          >
            About Juyue
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

const teamData = [
  // 網站製作
  {
    photo: "昱翔.jpg",
    name: "黃昱翔",
    info: "架構規劃、網頁製作",
    link: "https://www.instagram.com/huangjerry1031/",
  },
  {
    photo: "仁澤.jpg",
    name: "黃仁澤",
    info: "專案管理、網頁開發",
    link: "https://Juyue.tw",
  },
  {
    photo: "雲茵.JPG",
    name: "陳雲茵",
    info: "介面設計、網頁製作",
    link: "https://www.instagram.com/charlene.chen_/",
  },
  {
    photo: "子涵.png",
    name: "陳子涵",
    info: "架構規劃、網頁製作、資料串接",
    link: "https://tzuhanchen.vercel.app/",
  },
];

function shuffleArray<T>(array: T[]): T[] {
  // Function to shuffle the array using Fisher-Yates algorithm
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function MemberCard({ index }: { index: number }) {
  return (
    <Col>
      <Card style={{
				margin: "0 auto",
        border: "0",
        borderRadius: "18px",
        boxShadow: '0 7px 30px -10px rgba(150,170,180,0.5)',
				maxWidth: "270px"
      }}>
        <Card.Img
          style={{
            aspectRatio: "1/1",
            borderRadius: "18px",
            backgroundImage: `url("/images/team/${teamData[index].photo}")`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            content: "close-quote",
						border: "none"
          }}
        />

        <Card.Body className="p-3">
          <Card.Title className="fw-bold mb-3" style={{ color: "#152536" }}>
            {teamData[index].name}
          </Card.Title>

          <Card.Text
            style={{
              fontSize: "16px",
              whiteSpace: "pre-wrap",
              color: "#68717A",
            }}
          >
            {teamData[index].info}
          </Card.Text>

          <a href={teamData[index].link} target="_blank" rel="noreferrer">
            <Button variant="light">
              <i className="bi-globe"></i>
            </Button>
          </a>
        </Card.Body>
      </Card>
    </Col>
  );
}

function WebsiteCards() {
  shuffleArray(teamData); // Randomize the order of developers

  let websiteCards = [];
  for (let i = 0; i <= 3; i++) {
    websiteCards.push(<MemberCard index={i} key={i} />);
  }

  return (
    <Row xs={1} sm={2} md={3} lg={4} className="pb-5 g-4">
      {websiteCards}
    </Row>
  );
}

export default function Team() {
  return (
    <Container className="py-5" style={{ maxWidth: "1200px" }}>
      <JuyueCards />
      <h4 className="mt-1 mb-5 text-center fw-bold">網站開發團隊</h4>
      <WebsiteCards />
      <div style={{ marginBottom: "6rem" }}></div>
    </Container>
  );
}