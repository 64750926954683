import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";


function QuizAnswer() {
  return (
    <>
      <Container style={{ marginTop: "8rem" }} className="mb-5">
        <Row className="px-5 mb-5">
          <img
            src="./images/QuizPage/PAD.png"
            alt="..."
            className="img-fluid"
            style={{ width: "200px", height: "180px" }}
          />
          <Col className="m-2">
            <h5>自覺注意力中斷(Perceived Attention Discontinuity, PAD)</h5>
            <p className="mt-3">
              探討學習者在網路使用中，是否會優先處理與學習任務無關的事務，例題包含「當利用網路在讀書寫報告遇到瓶頸時，我會不自覺地開啟其他程式、網頁
              或手機」使用李克式五點量表(5：完全不符合、4：大部分不符合、3：有些符合、2：大部分符合、1：完全符合)進行評分分數越高表示學習者利用網路進行學習時，
              容易處理和線上學習無關事件。
            </p>
            <a href="https://www.instagram.com/p/ChuVtmSpMh_/">
              <Button
                style={{
                  background: "#F9F9F9",
                  width: "280px",
                  height: "3rem",
                  color: "black",
                }}
                variant="light"
              >
                詳細介紹
              </Button>
            </a>
          </Col>
        </Row>

        <Row className="px-5 mb-5">
          <img
            src="./images/QuizPage/SMN.png"
            alt="..."
            className="img-fluid"
            style={{ width: "200px", height: "180px" }}
          />
          <Col className="m-2">
            <h5>社交媒體通知(Social Media Notification, SMN)</h5>
            <p className="mt-3">
              探討學習者在使用網路學習中是否會因為社交媒體通知影響，導致學習者產生數位分心，其中例題包含「當在做正事時，我常覺得我喜歡的網頁、網站可能正在發生有趣的事情」。使用李克式五點量表(5：完全不符合、4：大部分不符合、3：有些符合、2：大部分符合、1：完全符合)進行評分。分數越高表示學習者利用網路進行學習時，容易被線上學習以外的事件所吸引
            </p>
            <a href="https://www.instagram.com/p/CiSXEWgJvsc/">
              <Button
                style={{
                  background: "#F9F9F9",
                  width: "280px",
                  height: "3rem",
                  color: "black",
                }}
                variant="light"
              >
                詳細介紹
              </Button>
            </a>
          </Col>
        </Row>

        <Row className="px-5 mb-5">
          <img
            src="./images/QuizPage/LT.png"
            alt="..."
            className="img-fluid"
            style={{ width: "200px", height: "180px" }}
          />
          <Col className="m-2">
            <h5>社交懸念 (Lingering thoughts, LT)</h5>
            <p className="mt-3">
              探討學習者是否會一直關注網路上正在發生的事情，並不是將注意力全部放置在學習任務上，其中例題包含「讀書或做作業時，我會立即注意到即時通訊軟體的訊息警示
              (例如
              LINE」)」。使用李克式五點量表(5：完全不符合、4：大部分不符合、3：有些符合、2：大部分符合、1：完全符合)進行評分。分數越高表示學習者利用網路進行學習時，容易對網路學習不相干事的抱持期待心情與警覺狀態。
            </p>
            <a href="https://www.instagram.com/p/CiAV0yyJNoq/">
              <Button
                style={{
                  background: "#F9F9F9",
                  width: "280px",
                  height: "3rem",
                  color: "black",
                }}
                variant="light"
              >
                詳細介紹
              </Button>
            </a>
          </Col>
        </Row>

        <Row className="px-5 mb-5">
          <img
            src="./images/QuizPage/BS.png"
            alt="..."
            className="img-fluid"
            style={{ width: "200px", height: "180px" }}
          />
          <Col className="m-2">
            <h5>行為策略(Behavioral Strategies, BS)</h5>
            <p className="mt-3">
              探討學習者是否會一直關注網路上正在發生的事情，並不是將注意力全部放置在學習任務上，其中例題包含「讀書或做作業時，我會立即注意到即時通訊軟體的訊息警示
              (例如
              LINE」)」。使用李克式五點量表(5：完全不符合、4：大部分不符合、3：有些符合、2：大部分符合、1：完全符合)進行評分分數越高表示學習者利用網路進行學習時，容易對網路學習不相干事的抱持期待心情與警覺狀態。
            </p>
            <a href="https://www.instagram.com/p/CjIc01jpVtO/">
              <Button
                style={{
                  background: "#F9F9F9",
                  width: "280px",
                  height: "3rem",
                  color: "black",
                }}
                variant="light"
              >
                詳細介紹
              </Button>
            </a>
          </Col>
        </Row>

        <Row className="px-5 mb-5">
          <img
            src="./images/QuizPage/OA.png"
            alt="..."
            className="img-fluid"
            style={{ width: "200px", height: "180px" }}
          />
          <Col className="m-20">
            <h5>結果歸因(Outcome Appraisal, OA)</h5>
            <p className="mt-3">
              探討學習者如何評價其網路學習結果和所連結的情緒。其中例題包含「「當我發現我正在瀏覽無關的網頁或是玩電子遊戲時，我覺得很有罪惡感」。使用李克式五點量表(5：完全不符合、4：大部分不符合、3：有些符合、2：大部分符合、1：完全符合)進行評分。分數越高表示學習者利用網路進行學習時，容易利用內在動機中獲得成功或避免內疚感。
            </p>
            <a href="https://www.instagram.com/p/CjaiXysp1S1/">
              <Button
                style={{
                  background: "#F9F9F9",
                  width: "280px",
                  height: "3rem",
                  color: "black",
                }}
                variant="light"
              >
                詳細介紹
              </Button>
            </a>
          </Col>
        </Row>

        <Row className="justify-content-center my-5">
          <Link
            to="/Quizpage"
            style={{
              textDecoration: "none",
              borderRadius: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#E4C444",
              width: "280px",
              height: "3rem",
              color: "white",
            }}
          >
            回到測驗問卷
          </Link>
        </Row>
      </Container>
    </>
  );
}



export default QuizAnswer;
