import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

function Homepage() {
  return (
    <>
      <Container
        style={{
          marginTop: "10rem",
          marginBottom: "30rem",
        }}
      >
        <Row className="px-5">
          <Col md={6} lg={12} className="m-2">
            <h5>數位分心簡要介紹：</h5>
            <p className="mt-3">
              HELLO大家好😁👋
              我們是國立陽明交通大學教育研究所的吳俊育老師的「數位分心」研究團隊。
              你有沒有過以下的經驗呢？
              在學習環境下使用手機、平板電腦、筆記型電腦等，原本以為可以輔助學習、使學習更加有趣。沒想到，注意力卻時常被干擾，反而漸漸被遊戲、臉書、IG等各種社群網站影音，或是朋友傳來的訊息的叮噹叮噹給吸引。
              這就是「數位分心」喔～📱💻😳
              你想更深入了解數位分心嗎？趕快進來看看吧！
            </p>
          </Col>
          <Col md={6} lg={12} className="m-2">
            <h5>數位分心介紹：</h5>
            <p className="mt-3">
              學習者進行網路資訊搜尋與學習時，容易在學習任務中產生多工的現象(Wu,
              020)。部分學習者還可能因社群媒體的干擾而導致注意力分散，中斷了正在進行中的學習任務，上述情形即為「數位分心」(Digital
              Distraction)(Wu, 2015, 2017)。
              國立陽明交通大學教育所吳俊育老師所開發的線上學習注意力奮起與調節策略量表
              (Online Learning Motivated Attention and Regulation Strategies,
              OL-MARS)
              ，量表目的為檢測學習者依據自己線上學習的經驗，反思自我的數位分心狀態。包含五個構念：自覺注意力中斷
              (PAD)、社交媒體通知(SMN)、社交懸念 (LT)、行為策略(BS)、結果評鑑
              (OA) 。 其相關研究結果請詳見參考資料網頁中的『相關論文發表』。
            </p>
            <Button
              href="https://www.instagram.com/distractionlovesme/"
              style={{
                marginTop: "3rem",
                padding: "0.5rem 4rem",
                textAlign: "center",
                background: "#90C2D9",
                width: "auto",
                color: "white",
              }}
              variant="light"
            >
              <h5 style={{ whiteSpace: "nowrap", marginBottom: "0" }}>
                立即私訊我們
              </h5>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Homepage;
