import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer() {
  return (
    <Container
      fluid
      style={{
        backgroundColor: "#F5F5F5",
        zIndex: "1",
      }}
      className="py-2"
    >
      <Row>
        <Col sm={5}>
          <p className="m-0 text-start">ML²LAB @ NYCU,TAIWAN</p>
        </Col>
        <Col sm={7}>
          <p style={{ color: "#757575" }} className="m-0 text-end">
            Copyright© NYCU ML²LAB All rights reserved.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;