import React from 'react'
import Container from 'react-bootstrap/Container'
import { Col, Row } from 'react-bootstrap'
import SubNav from '../components/SubNav'

function Lessonplanpage() {
  return (
    <>
      <Container style={{ marginTop: '6rem' }}>
        <Row className=" justify-content-center">
          <Col xs={10} md={6} className="p-2">
            <div style={{ height: '18rem' }} className="p-5">
              <div
                className="mx-2 text-dark text-decoration-none d-flex align-items-center"
                style={{ height: '100%' }}
              >
                <div>
                  <h4>我們有一套專門幫助學生規劃的資源！</h4>
                  <h6>多元的教材與專業的師資</h6>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={10} md={6} className="p-2">
            <div
              style={{
                height: '18rem',
                backgroundImage: `url(/images/swiper/2.jpg)`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
              className="p-3 shadow rounded-4"
            ></div>
          </Col>
        </Row>
      </Container>

      <Container className="my-5">
        <Row>
          <Col className="">
            <h5>快速下載課程規劃資源</h5>
          </Col>
        </Row>
      </Container>

      <SubNav />

      <div style={{ marginBottom: '18rem' }}></div>
    </>
  )
}

export default Lessonplanpage
