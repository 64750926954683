import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import Main from './Main';
import Footer from './components/Footer';

import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>

    <Main />
    <Footer/>
      
  </React.StrictMode>

);

