import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'

// Pages
import Build from './building/Build'
import HomePage from './pages/Home'
import LessonPlanPage from './pages/LessonPlan'
import UserGuidance from './pages/UserGuidance'
import QA from './pages/QA'
import References from './pages/References'
import TeamIntroduction from './pages/TeamIntroduction'
import WebsiteDevelopers from './pages/WebsiteDevelopers'
import KnowMorePage from './pages/KnowMore'
import QuizAnswerPage from './pages/QuizAnswer'
import QuizPage from './pages/Quiz'
import ClassPlan from './pages/ClassPlan'

import NavBar from './components/NavBar'

function App() {
  return (
    <Router>
      <ScrollToTop />
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Home" element={<HomePage />} />
        <Route path="/ClassPlan/:activeButtonId" element={<ClassPlan />} />
        <Route path="/LessonPlan" element={<LessonPlanPage />} />
        <Route path="/UserGuidance" element={<UserGuidance />} />
        <Route path="/References" element={<References />} />
        <Route path="/QA" element={<QA />} />
        <Route path="/TeamIntroduction" element={<TeamIntroduction />} />
        <Route path="/KnowMorePage" element={<KnowMorePage />} />
        <Route path="/QuizAnswerPage" element={<QuizAnswerPage />} />
        <Route path="/QuizPage" element={<QuizPage />} />
        <Route path="/WebsiteDevelopers" element={<WebsiteDevelopers />} />
        {/* Using path="*"" means "match anything", so this route
            acts like a catch-all for URLs that we don't have explicit
            routes for. */}
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Router>
  )
}

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function NoMatch() {
  return <Build />
}

export default App
