import React from 'react';
//import logo from "images/ct.jpeg";
import './Build.css';
import 'animate.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Container>
          <img
            src={"/images/juyue.svg"}
            className="App-logo animate__animated  animate__bounce"
            alt="logo"
          />
          <Row className="justify-content-md-center">
            <Col xs lg="8">
              <h2 className="mt-5">"The Future ML2LAB is Here"</h2>
              <h6 className="mt-5">
                We strive to stay ahead of the curve and bring innovative
                solutions to the table. From cutting-edge technology to
                revolutionary ideas, our mission is to shape the future of
                website and make our world a better place. Our team of experts
                is passionate about pushing the boundaries of what is possible
                and providing services that make a real difference. Visit us
                today to see how we can help you make the future happen.
              </h6>
              <Button
                className="mt-5"
                variant="outline-light"
                href="https://Juyue.tw"
                target="_blank"
                rel="noopener noreferrer"
              >
                About Juyue
              </Button>
            </Col>
          </Row>
        </Container>
      </header>
      <footer className="App-footer">
        <Container fluid className="container-fluid pb-3">
          <Row className="" style={{ fontSize: "0.65rem" }}>
            <div className="col-12 p-0">
              <p
                style={{ fontSize: "0.65rem" }}
                className="text-center mb-0 p-0 text-light"
              >
                Copyright© All rights reserved.
              </p>
              <p
                style={{ fontSize: "0.65rem" }}
                className="text-center mb-0 p-0 text-light"
              >
                This website is developed by Juyue Team.
              </p>
            </div>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default App;
