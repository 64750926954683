
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function References() {
  return (
    <>
      <Container style={{ marginTop: "6rem" }} className="p-4">
        <Row>
          <h4 className="p-3" style={{ textAlign: "center" }}>
            參考資料
          </h4>
        </Row>
        <br />
        <Row className="p-3">
          <h5 className="pb-1">相關網站連結</h5>
        </Row>
        <Row className="mx-1">
          <h6>
            國立陽明交通大學教育所:{" "}
            <a
              className="text-dark text-decoration-none"
              href="https://education.nycu.edu.tw/"
            >
              {" "}
              https://education.nycu.edu.tw/
            </a>
          </h6>
          <h6>
            吳俊育老師實驗室網站:{" "}
            <a
              className="text-dark text-decoration-none"
              href="https://ml2.lab.nycu.edu.tw/"
            >
              {" "}
              https://ml2.lab.nycu.edu.tw/
            </a>
          </h6>
          <h6>
            小狐狸分心實驗室Ig:{" "}
            <a
              className="text-dark text-decoration-none"
              href="https://www.instagram.com/distractionlovesme/"
            >
              {" "}
              https://www.instagram.com/distractionlovesme/
            </a>
          </h6>
        </Row>
        <br />
        <Row className="p-3">
          <h5 className="pb-1">繪本參考</h5>
        </Row>
        <Row className="mx-1">
          <h6>
            不要再滑那個笨手機
            <br />
            <a
              className="text-dark text-decoration-none"
              href="https://www.books.com.tw/products/0010897933?sloc=main"
            >
              https://www.books.com.tw/products/0010897933?sloc=main
            </a>
          </h6>
          <h6>
            手機的規矩
            <br />
            <a
              className="text-dark text-decoration-none"
              href="https://www.books.com.tw/products/0010874495?sloc=main"
            >
              https://www.books.com.tw/products/0010874495?sloc=main
            </a>
          </h6>
          <h6>
            數位世界的孩子3:為什麼我要注意網路健康？
            <br />
            <a
              className="text-dark text-decoration-none"
              href="https://www.books.com.tw/products/E050048408?sloc=main"
            >
              https://www.books.com.tw/products/E050048408?sloc=main
            </a>
          </h6>
          <h6>
            好想玩手機
            <br />
            <a
              className="text-dark text-decoration-none"
              href="https://www.books.com.tw/products/0010884596?sloc=main"
            >
              https://www.books.com.tw/products/0010884596?sloc=main
            </a>
          </h6>
          <h6>
            如果你給老鼠玩手機:到底該不該玩手機？
            <br />
            <a
              className="text-dark text-decoration-none"
              href="https://www.books.com.tw/products/0010840971"
            >
              https://www.books.com.tw/products/0010840971
            </a>
          </h6>
          <h6>
            魔幻奇機來襲
            <br />
            <a
              className="text-dark text-decoration-none"
              href="https://www.books.com.tw/products/0010834012?sloc=main"
            >
              https://www.books.com.tw/products/0010834012?sloc=main
            </a>
          </h6>
        </Row>
        <br />
        <Row className="p-3">
          <h5 className="pb-1">相關新聞</h5>
        </Row>
        <Row className="mx-4">
          <ul>
            <li>
              <a
                className="text-dark text-decoration-none"
                href="吳俊育. (2021, November 30).平板好玩好學 消弭數位落差.聯合報/聯合新聞網."
              >
                吳俊育. (2021, November 30).平板好玩好學
                消弭數位落差.聯合報/聯合新聞網.
              </a>
            </li>
            <li>
              <a
                className="text-dark text-decoration-none"
                href="https://www.cw.com.tw/article/5118729?from=search&fbclid=IwAR2NU6j4cJdNCeBddsGoFwEMZ07DXg85o5ehYXRru3AmL8QnWUzbn5PJF1c"
              >
                林佳誼. (2021, November
                2).疫後教育新常態》線上+實體課怎麼整合？「混成教學」全攻略.天下雜誌線上版735期.
              </a>
            </li>
            <li>
              <a
                className="text-dark text-decoration-none"
                href="https://udn.com/news/story/6898/4816008"
              >
                潘乃欣. (2020, Auguest 28). 小學平板上課成績差？分心是大敵.
                聯合報/聯合新聞網.
              </a>
            </li>
          </ul>
        </Row>
        <br />
        <Row className="p-3">
          <h5 className="pb-1">相關論文發表</h5>
        </Row>
        <Row className="mx-4">
          <ul>
            <li>
              Wu, J.-Y. (2020). The predictive validities of individual
              working-memory capacity profiles and note-taking strategies on
              online search performance. Journal of Computer Assisted Learning,
              36(6), 876-889. <br />
              <a
                className="text-dark text-decoration-none"
                href="https://doi.org/10.1111/jcal.12441"
              >
                https://doi.org/10.1111/jcal.12441
              </a>
              (SSCI: 2020 IF: 3.862, Q1)
            </li>
            <li>
              Wu, J.-Y.*, & Cheng, T.† (2019). Who is better adapted in learning
              online within the personal learning environment? Relating gender
              differences in cognitive attention networks to digital
              distraction. Computers & Education, 128, 312–329. <br />
              <a
                className="text-dark text-decoration-none"
                href="https://doi.org/10.1016/j.compedu.2018.08.016"
              >
                https://doi.org/10.1016/j.compedu.2018.08.016
              </a>
              (SSCI: 2019 IF: 5.296, Q1)
            </li>
            <li>
              Wu, J.-Y.*, & Xie, C.† (2018). Using time pressure and note-taking
              to prevent digital distraction behavior and enhance online search
              performance: Perspectives from the load theory of attention and
              cognitive control. Computers in Human Behavior, 88, 244–254.{" "}
              <br />
              <a
                className="text-dark text-decoration-none"
                href="https://doi.org/10.1016/j.chb.2018.07.008"
              >
                https://doi.org/10.1016/j.chb.2018.07.008
              </a>
              (SSCI: 2018 IF: 4.306)
            </li>
            <li>
              Wu, J.-Y. (2017). The indirect relationship of media multitasking
              self-efficacy on learning performance within the personal learning
              environment: Implications from the mechanism of perceived
              attention problems and self-regulation strategies. Computers &
              Education, 106, 56–72. <br />
              <a
                className="text-dark text-decoration-none"
                href="https://doi.org/10.1016/j.compedu.2016.10.010"
              >
                https://doi.org/10.1016/j.compedu.2016.10.010
              </a>
              (SSCI: 2017 IF: 4.538)
            </li>
            <li>
              Wu, J.-Y. (2015). University students' motivated attention and use
              of regulation strategies on social media. Computers & Education,
              89, 75-90. <br />
              <a
                className="text-dark text-decoration-none"
                href="https://doi.org/10.1016/j.compedu.2015.08.016"
              >
                https://doi.org/10.1016/j.compedu.2015.08.016
              </a>
              (SSCI: 2015 IF: 2.881)
            </li>
          </ul>
        </Row>
      </Container>
      <Container
        style={{
          display: "flex",
          width: "100%",
          margin: "0rem auto 4rem auto",
        }}
        className="p-5 justify-content-center"
      >
        <Row style={{ display: "flex", alignItems: "center" }} className="px-5">
          <Col>
            <h5 className="text-center" style={{ whiteSpace: "nowrap" }}>
              找不到想要問的答案嗎？
            </h5>
          </Col>
          <Col className="text-center">
            <Button
              href="https://www.instagram.com/distractionlovesme/"
              style={{
                padding: "0.5rem 4rem",
                textAlign: "center",
                background: "#90C2D9",
                width: "auto",
                color: "white",
              }}
              variant="light"
            >
              <h5 style={{ whiteSpace: "nowrap", marginBottom: "0" }}>
                立即私訊我們
              </h5>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default References;