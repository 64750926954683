import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function QABasic() {
  return (
    <>
      <Container style={{ marginTop: "6rem" }} className="px-5">
        <Row style={{ display: "flex", alignItems: "center" }} className="p-5">
          <Col>
            <h2 style={{ whiteSpace: "nowrap", lineHeight: "50px" }}>
              有任何的問題想問我們嗎？
              <br /> 先看看我們的QA吧！
            </h2>
            <br />
            <h5 style={{ whiteSpace: "nowrap" }}>多元的教材與專業的師資</h5>
          </Col>
          <Col style={{ width: "15rem" }}>
            <img
              style={{ borderRadius: "1rem", minWidth: "12rem", width: "100%" }}
              src="./images/QAphoto.jpg"
              alt=""
            />
          </Col>
        </Row>
      </Container>
      <Container className="px-5">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Q1 我是老師(或學校職員)，若我對數位分心課程有興趣我要怎麼申請呢？
            </Accordion.Header>
            <Accordion.Body>
              若有需求請老師(或學校職員)向數位分心網站人員程小姐洽詢。
              <br /> 客服信箱：iammo0715@nycu.edu.tw
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Q2 我是學生，若我參加數位分心課程，我的參與資料會外流嗎？
            </Accordion.Header>
            <Accordion.Body>
              你/妳提供的資料我們會負起妥善保密的責任，不會向任何人透漏有關你/妳的資料，我們會用代碼來取代您真實的姓名，除研究團隊外，無人可知道您的真實身分。重新編碼的資料會保存在有設定密碼的實驗室電腦資料庫裡後，再將紙本資料銷毀，所有的資料只會使用在這次的研究分析上。最後僅以願意參與之學生資料進行統計分析、驗證研究成果。不願意參與之學生所有資料皆會刪除且不會作為本研究之對照資料
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Q3
              我是學生，若我不想參加數位分心課程且不想提供我的資料，這樣對我的學校成績會有影響嗎？
            </Accordion.Header>
            <Accordion.Body>
              學生可以自由決定要不要參加研究，無論你是否參加研究，都不會影響你的學業成績及老師對您的觀感，學校老師是「協助」本研究進行而已。若你有感覺任何生理或心理的不舒服或你沒有意願繼續參加之後的研究，都有權利隨時告知主持人與教學團隊，且無條件終止並退出本研究。班級中是否無論參與研究，學生均進行一樣的活動、教材及問卷，但於研究同意書簽署不同意，或未繳交參與研究同意書者，本次課程將不會將其列為研究對象且將所有不同意參與研究同學之資料進行刪除，並不會作為本課程研究之對照資料。
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Q4 我是家長，若我對孩子所參與的數位分心課程有疑問可以找誰詢問呢？
            </Accordion.Header>
            <Accordion.Body>
              若有疑問可向數位分心網站人員程小姐洽詢。
              <br /> 客服信箱：iammo0715@nycu.edu.tw
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Q5 數位分心課程的素材都是免費使用的嗎？
            </Accordion.Header>
            <Accordion.Body>
              數位分心課程的素材並非完全免費，若有課程需求可向數位分心網站人員程小姐洽詢。
              <br /> 客服信箱：iammo0715@nycu.edu.tw
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              Q6 我是老師，了解學生的數位分心程度對課程有什麼幫助呢?
            </Accordion.Header>
            <Accordion.Body>
              數位分心被發現和學生的成績低落、課堂學習表現不佳和自我陳述對課程材料的理解度低有關，因此我們希望透過數位分心課程教材與線上學習注意力訓練的課程與教材，讓後疫時期的學生自主學習能夠更有效率的進行。
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              Q7 我是學生，了解自己的數位分心程度對我有什麼幫助呢?
            </Accordion.Header>
            <Accordion.Body>
              本次課程希望讓數位工具的使用可以有效地幫助學生學習，學生可以有策略地維持數位學習過程中注意力的維持和知識的建構，這樣才可能降低數位分心，並達成學習目標。
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              Q8 我是家長，了解孩子的數位分心程度會有什麼幫助呢?
            </Accordion.Header>
            <Accordion.Body>
              數位分心被發現和學生的成績低落、課堂學習表現不佳和自我陳述對課程材料的理解度低有關，因此我們希望透過數位分心課程教材與線上學習注意力訓練的課程與教材，讓後疫時期的學生自主學習能夠更有效率的進行。
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              Q9 我要如何知道我的數位分心程度呢?
            </Accordion.Header>
            <Accordion.Body>
              您可以在數位分心網站上進行免費的數位分心程度測驗，並再依照測驗結果進行數位分心課程，也可以在數位分心課程後再次進行數位分心程度測驗，檢驗課程內容是否對您有所幫助。
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>
              Q10 我是否可以知道自己的數位分心程度如何
            </Accordion.Header>
            <Accordion.Body>
              數位分心網站的數位分心程度測驗完畢後馬上就會有結果。
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>Q11 數位分心課程如何收費？</Accordion.Header>
            <Accordion.Body>
              數位分心課程完全免費，若有需求請向數位分心網站人員程小姐洽詢。
              <br /> 客服信箱：iammo0715@nycu.edu.tw
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
      <Container
        style={{
          display: "flex",
          width: "100%",
          margin: "0rem auto 4rem auto",
        }}
        className="p-5 justify-content-center"
      >
        <Row style={{ display: "flex", alignItems: "center" }} className="px-5">
          <Col>
            <h5 className="text-center" style={{ whiteSpace: "nowrap" }}>
              找不到想要問的答案嗎？
            </h5>
          </Col>
          <Col className="text-center">
            <Button
              href="https://www.instagram.com/distractionlovesme/"
              style={{
                padding: "0.5rem 4rem",
                textAlign: "center",
                background: "#90C2D9",
                width: "auto",
                color: "white",
              }}
              variant="light"
            >
              <h5 style={{ whiteSpace: "nowrap", marginBottom: "0" }}>
                立即私訊我們
              </h5>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default QABasic;