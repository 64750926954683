import React from 'react';
// import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const teamData = [
	// 教授
	{
		photo: '吳俊育-照片1.jpeg',
		position: '課程計畫主持人',
		name: '吳俊育教授 Dr. Jiun-Yu Wu',
		info: '國立陽明交通大學教育研究所\n\n吳俊育教授研究團隊以人為中心的數位科技環繞個人學習環境 (PLE) 為場景。利用學習分析 (Learning Analytics) 鎖定在個人學習環境中，整合社交媒體於混成式教與學現場，檢視影響學習者學習成效的正負向因子，以及互動歷程與動力變化 (interaction progress and dynamics change)。團隊整合教育資料探勘 (Educational Data Mining, EDM) 與學習分析 (Learning Analytics, LA) 框架，串連網路爬蟲、資料探勘、自然語言處理、機器學習、社會網絡分析、以及心理量測與統計建模等，非結構與結構式資料分析技術。'
	},
	// 學生
	{
		photo: '姿螢.jpeg',
		position: '課程計畫研究助理',
		name: '程姿螢 Tzu-ying Cheng',
		info: '協助課程計畫行政事務\n\n聯絡方式\nE-mail: iammo0715@nycu.edu.tw'
	},
	{
		photo: '欣蓉_個人照-425x425.jpeg',
		position: '小學數位分心課程計畫設計',
		name: '鍾欣蓉 Hsin-Jung Chung',
		info: '學術專長：\n小學教育、影像編輯製作、程式語言 (R, Python)\n\n研究興趣：\n學生言語行為對學習表現之研究'
	},
	{
		photo: '以箴.jpeg',
		position: '國中數位分心課程計畫設計',
		name: '阮以箴 Yi-Chen Juan',
		info: '學術專長：\n寫作教學, 教育心理學\n\n研究興趣：\n網路學習社群輔助寫作教學、社群媒體使用'
	},
	{
		photo: '昱霖.png',
		position: '高中數位分心課程計畫設計',
		name: '何昱霖 Yu-Lin Ho',
		info: '學術專長：\n基礎統計分析、程式語言 (R)、聯合國兒童權利公約講師\n\n研究興趣：\n延宕行為、自主學習、數位分心'
	},
	// 影片設計
	{
		photo: '卓琦.JPG',
		position: '小學影片動畫設計',
		name: '陳卓琪',
		link: 'https://www.instagram.com/choinc__/'
	},
	{
		photo: '冠茗.JPG',
		position: '高中影片動畫設計',
		name: '王冠茗',
		link: 'https://www.instagram.com/mingsyou_design/'
	},
	{
		photo: '念晴.JPG',
		position: '影片配樂設計',
		name: '張念晴',
		link: 'mailto:francex0401@gmail.com'
	}
];

function MemberCard({index}: {index: number}) {
	return (
    <Col>
      <Card
        style={{
          border: "0",
          borderRadius: "18px",
          boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
        }}
        className={`h-100 ${
          index === 0 ? "d-sm-flex flex-sm-row" : "d-lg-flex flex-lg-row"
        }`}
      >
        <Card.Img
          style={{
            aspectRatio: "1/1",
            borderRadius: "18px",
            minWidth: `${index === 0 ? "40%" : ""}`,
            backgroundImage: `url("/images/team/${teamData[index].photo}")`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            content: "close-quote",
            border: "none"
          }}
        />

        <Card.Body
          className={`p-3 ${index === 0 ? "p-md-5" : ""}`}
          style={{ minWidth: `${index === 0 ? "" : "60%"}` }}
        >
          {teamData[index].position !== undefined ? (
            <Card.Text className="h6">{teamData[index].position}</Card.Text>
          ) : (
            ""
          )}

          <Card.Title
            className={`fw-bold mb-3 ${index === 0 ? "mb-lg-4" : "mb-lg-3"} `}
            style={{ color: "#152536" }}
          >
            {teamData[index].name}
          </Card.Title>

          {teamData[index].info !== undefined ? (
            <Card.Text
              style={{
                fontSize: "16px",
                whiteSpace: "pre-wrap",
                color: "#68717A",
              }}
            >
              {teamData[index].info}
            </Card.Text>
          ) : (
            ""
          )}

          {teamData[index].link !== undefined ? (
            <a href={teamData[index].link} target="_blank" rel="noreferrer">
            	<Button variant="light">
                {index !== 7 ? (
                  <i className="bi-instagram"></i>
                ) : (
                  <i className="bi-envelope"></i>
                )}
              </Button>
            </a>
          ) : (
            ""
          )}
        </Card.Body>
      </Card>
    </Col>
  );
}

function ProfessorCard() {
	return(
		<Row className="mb-5">
			<MemberCard index={0} key={0}/>
		</Row>
	);
}

function StudentCards() {
	let studentCards = [];
	for (let i = 1; i <= 4; i++) {
		studentCards.push(<MemberCard index={i} key={i}/>);
	}

	return(
		<Row xs={1} sm={2} md={3} lg={2} className="pb-5 g-4">
			{ studentCards }
		</Row>
	);
}

function VideoDesignCards() {
	let videoDesignCards = [];
	for (let i = 5; i <= 7; i++) {
		videoDesignCards.push(<MemberCard index={i} key={i}/>);
	}

	return(
		<Row xs={1} sm={2} md={3} className="pb-5 g-4">
			{ videoDesignCards }
		</Row>
	);
}

function LinkToWebsiteDevelopers() {
	return(
		<Row className="mt-5 d-flex justify-content-center">
			<Link to="/WebsiteDevelopers" style={{ width: 'fit-content' }}>
				<Button variant="light"
				style={{ width: "auto", height: '3.5rem', 
				padding: "0.5rem 2rem",background: "#90C2D9", 
				color: "white", textAlign: "center" }}>
					<h5 style={{ marginBottom: 0 }}>網站開發團隊</h5>
				</Button>
			</Link>
		</Row>
	);
}

export default function TeamIntroduction() {
	return(
		<Container className="px-5 py-5" style={{ maxWidth: '1200px' }}>
			<h4 className="mt-5 mb-4 text-center fw-bold">研究團隊</h4>
			<ProfessorCard />
			<StudentCards />

			<h4 className="mt-5 mb-4 text-center fw-bold">影片設計團隊</h4>
			<VideoDesignCards />

			<LinkToWebsiteDevelopers />
		</Container>
	);
}