import React from 'react'

import { Link } from 'react-router-dom'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SubNav from '../components/SubNav'
import Swip from '../components/Swiper'
// import countapi from 'countapi-js'
import News from '../components/News'

function HomePage() {
  // countapi
  //   .get(
  //     "distractionlovesme.lab.nycu.edu.tw",
  //     "462cf614-7d01-4653-a5fb-4b5c0f4c550a"
  //   )
  //   .then((result) => {
  //     console.log("counter:" + result.value);
  //     console.log(typeof result.value);
  //     document.querySelector("#visits")!.textContent = String(result.value);
  //   });

  // countapi
  //   .hit(
  //     "distractionlovesme.lab.nycu.edu.tw",
  //     "462cf614-7d01-4653-a5fb-4b5c0f4c550a"
  //   )
  //   .then((result) => {
  //     console.log("counter: +1");
  //   });

  return (
    <>
      <Container style={{ marginTop: '6rem' }}>
        <Row className=" justify-content-center">
          <Col xs={10} md={6} className="p-2">
            <div
              style={{ position: 'relative', height: '18rem' }}
              className="p-5 shadow bg-white rounded-4"
            >
              <Link
                to="/KnowMorePage"
                className="mx-2 text-dark text-decoration-none"
              >
                <h4>
                  數位分心網提供學生多元學習資源依據年齡進到各學制的教材。
                </h4>
                <h6 className="font-weight-bold">多元的教材與專業的師資</h6>
                <p
                  style={{
                    position: 'absolute',
                    bottom: '2rem',
                    right: '2.5rem',
                  }}
                  className="text-dark text-end align-bottom"
                >
                  我想了解更多 →
                </p>
              </Link>
            </div>
          </Col>
          <Col xs={10} md={6} className="p-2">
            <div
              style={{ height: '18rem' }}
              className="shadow bg-white rounded-4"
            >
              <Swip />
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="my-3">
        <Row>
          <Col>
            <h5>快速進入專區</h5>
          </Col>
        </Row>
      </Container>
      <SubNav />
      <Container style={{ marginBottom: '5rem' }} className="mt-3">
        <Row>
          <Col md={12} lg={6} className="p-3">
            <h5>最新資訊</h5>
            <News />
          </Col>
          <Col md={12} lg={6} className="p-3">
            <h5>數位分心介紹</h5>
            <p>
              學習者進行網路資訊搜尋與學習時，容易在學習任務中產生多工的現象(Wu,
              020)。部分學習者還可能因社群媒體的干擾而導致注意力分散，中斷了正在進行中的學習任務，上述情形即為「數位分心」(Digital
              Distraction)(Wu, 2015, 2017)。
              國立陽明交通大學教育所吳俊育老師所開發的線上學習注意力奮起與調節策略量表
              (Online Learning Motivated Attention and Regulation Strategies,
              OL-MARS)
              ，量表目的為檢測學習者依據自己線上學習的經驗，反思自我的數位分心狀態。包含五個構念：自覺注意力中斷
              (PAD)、社交媒體通知(SMN)、社交懸念 (LT)、行為策略(BS)、結果評鑑
              (OA) 。 其相關研究結果請詳見參考資料網頁中的『相關論文發表』。
            </p>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default HomePage
