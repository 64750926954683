import React from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import { Col, Row } from 'react-bootstrap'
import useWindowSize from '../hooks/useWindowSize'

function SubNav() {
  const navigate = useNavigate()

  const handleButtonClick = (id: string) => {
    navigate(`/ClassPlan/${id}`)
  }

  return (
    <Container className="mt-3">
      <Row>
        <Col>
          <button
            id="primary"
            type="button"
            className={`btn my-2`}
            onClick={() => handleButtonClick('primary')}
            style={{
              background: '#6A99D0',
              width: '100%',
              height: '3.5rem',
              color: 'white',
            }}
          >
            國小
          </button>
        </Col>
        <Col>
          <button
            id="middle"
            type="button"
            className={`btn my-2`}
            onClick={() => handleButtonClick('middle')}
            style={{
              background: '#EF88AE',
              width: '100%',
              height: '3.5rem',
              color: 'white',
            }}
          >
            國中
          </button>
        </Col>
        <Col>
          <button
            id="high"
            type="button"
            className={`btn my-2`}
            onClick={() => handleButtonClick('high')}
            style={{
              background: '#F1CB20',
              width: '100%',
              height: '3.5rem',
              color: 'white',
            }}
          >
            高中
          </button>
        </Col>
        <Col>
          <button
            id="university"
            type="button"
            className={`btn  my-2`}
            onClick={() => handleButtonClick('university')}
            style={{
              background: '#9FCE63',
              width: '100%',
              height: '3.5rem',
              color: 'white',
            }}
          >
            大學
          </button>
        </Col>
      </Row>
    </Container>
  )
}

const getClassPlanImage = (activeButton: string) => {
  switch (activeButton) {
    case 'primary':
      return '/images/classplan01.png'
    case 'middle':
      return '/images/classplan02.png'
    case 'high':
      return '/images/classplan03.png'
    case 'university':
      return '/images/classplan04.png'
    default:
      return '/images/classplan01.png'
  }
}

const getLearningSheetLink = (activeButton: string) => {
  switch (activeButton) {
    case 'primary':
      return 'https://drive.google.com/file/d/1-qu20a9JrBuVfjPzA5kSxroU9Hr1Mia2/view'
    case 'middle':
      return 'build'
    case 'high':
      return 'build'
    case 'university':
      return 'none'
    default:
      return '#'
  }
}

const getVideoLink = (activeButton: string) => {
  switch (activeButton) {
    case 'primary':
      return 'https://www.youtube.com/watch?v=gZlCVf4fcWk'
    case 'middle':
      return 'https://www.youtube.com/watch?v=bvjJ1ct3m9A'
    case 'high':
      return 'https://www.youtube.com/watch?v=bvjJ1ct3m9A'
    case 'university':
      return 'none'
    default:
      return '#'
  }
}

// 以下為CP內部主架構程式

function ClassPlan() {
  const { activeButtonId: rawActiveButtonId } = useParams()
  // Provide a default value if rawActiveButtonId is undefined
  const activeButtonId = rawActiveButtonId || 'defaultId' // Replace "defaultId" with an appropriate default value

  const { width } = useWindowSize()

  return (
    <div style={{ marginTop: '8rem' }}>
      <div>
        <SubNav />
      </div>
      {width > 768 && (
        <>
          <Container style={{ display: 'flex' }}>
            <Col className="my-5">
              <h5 className="my-4">課程架構圖</h5>
              <img
                style={{ width: '90%' }}
                className="my-5"
                src={getClassPlanImage(activeButtonId)}
                alt=""
              />
              <img
                style={{ width: '90%' }}
                className="my-5"
                src="/images/classplan02.png"
                alt=""
              />
            </Col>
            <Col xs={3} className="my-5">
              {getLearningSheetLink(activeButtonId) !== 'none' && (
                <Link
                  to={getLearningSheetLink(activeButtonId)}
                  className=" text-dark text-decoration-none"
                >
                  <div style={{ alignSelf: 'flex-start', textAlign: 'start' }}>
                    <h5 className="my-4">課程學習單</h5>
                    <img
                      style={{ width: '95%' }}
                      src="/images/Csheet.png"
                      alt=""
                    />
                  </div>
                </Link>
              )}
              <br />
              {getVideoLink(activeButtonId) !== 'none' && (
                <Link
                  to={getVideoLink(activeButtonId)}
                  className="text-dark text-decoration-none"
                >
                  <div style={{ alignSelf: 'flex-start', textAlign: 'start' }}>
                    <h5 className="my-4">課程影片</h5>
                    <img
                      style={{ width: '95%' }}
                      src="/images/Cvideo.png"
                      alt=""
                    />
                  </div>
                </Link>
              )}
              <br />
              <Link to="/Quizpage" className=" text-dark text-decoration-none">
                <div style={{ alignSelf: 'flex-start', textAlign: 'start' }}>
                  <h5 className="my-4">數位分心測驗</h5>
                  <img
                    style={{ width: '55%' }}
                    src="/images/Cexam.png"
                    alt=""
                  />
                </div>
              </Link>
            </Col>
          </Container>
        </>
      )}
      {width <= 768 && (
        <>
          <Container>
            <Col className="my-5">
              <h5 className="my-4">課程架構圖</h5>
              <img
                style={{ width: '90%' }}
                className="my-5"
                src="/images/classplan01.png"
                alt=""
              />
              <img
                style={{ width: '90%' }}
                className="my-5"
                src="/images/classplan02.png"
                alt=""
              />
            </Col>
            <Col style={{ display: 'flex' }} className="my-5" xs="12">
              <Link to="*" className=" text-dark text-decoration-none">
                <div style={{ alignSelf: 'flex-start', textAlign: 'start' }}>
                  <h6 className="my-4">課程學習單</h6>
                  <img
                    style={{ width: '90%' }}
                    src="/images/Csheet.png"
                    alt=""
                  />
                </div>
              </Link>
              <br />
              <Link to="*" className=" text-dark text-decoration-none">
                <div style={{ alignSelf: 'flex-start', textAlign: 'start' }}>
                  <h6 className="my-4">課程影片</h6>
                  <img
                    style={{ width: '90%' }}
                    src="/images/Cvideo.png"
                    alt=""
                  />
                </div>
              </Link>
              <br />
              <Link to="/Quizpage" className=" text-dark text-decoration-none">
                <div style={{ alignSelf: 'flex-start', textAlign: 'start' }}>
                  <h6 className="my-4">數位分心測驗</h6>
                  <img
                    style={{ width: '90%' }}
                    src="/images/Cexam.png"
                    alt=""
                  />
                </div>
              </Link>
            </Col>
          </Container>
        </>
      )}
      <br />
      <br />
    </div>
  )
}

export default ClassPlan
