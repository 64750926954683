import React from 'react';

import Container from 'react-bootstrap/Container';
import Iframe from 'react-iframe'
import Row from 'react-bootstrap/Row'
import { Link } from "react-router-dom";


function Quizpage() {
  return (
    <>
      <div style={{ marginTop: "6rem" }}></div>

      <Iframe
        url="https://docs.google.com/forms/d/e/1FAIpQLSeIGn8O8Qh75RJeitP36tnzmWoQAkkgiazPrAvNDAQ1rd9TlA/viewform"
        width="100%"
        height="1100px"
        className=""
        id="QuizUrl"
        display="block"
        position="relative"
      />

      <Container className="my-5">
        <Row className="justify-content-md-center">
          <Link
            to="/QuizAnswerPage"
            style={{
              textDecoration: "none",
              borderRadius: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#E4C444",
              width: "280px",
              height: "3rem",
              color: "white",
            }}
          >
            觀看測驗結果
          </Link>
        </Row>
      </Container>
    </>
  );
}



export default Quizpage;
